import { SnapshotOut } from 'mobx-state-tree';
import { ePhase, eScreen, IParams } from './Common';

export enum CellAddress {
    X,
    Y
};

export enum CellAddressType {
    POUR_NUM = 'POUR_NUM',
    FLOOR_NUM = 'FLOOR_NUM',
    ACT_STRING = 'ACT_STRING',
    TOWER_STRING = 'TOWER_STRING',
    UNIT_TYPE_STRING = 'UNIT_TYPE_STRING',
    NULL = 'NULL'
};


export enum BrickTypes {
    S = 'STRUCTURES',
    F = 'FINISHING',
    // FCA = 'FINISHING_TCA'
};

export type IBrickTypes = keyof typeof BrickTypes;

export enum BricksView {
    CONVENTIONALS = "CONVENTIONAL_STRUCTURES",
    CONVENTIONALF = "CONVENTIONAL_FINISHING",
    FINLP = "FINISHING_LANDING_PAGE"
};

export enum BricksColl {
    POUR = "POUR",
    FINFS = "FINFS",
    FINLP = "FINLP"
};

export enum Pages {
    DASHBOARD_STRUCTURES_TOWER_LANDING = "DASHBOARD_STRUCTURES_TOWER_LANDING",
    DASHBOARD_STRUCTURES_TOWER_FULLSCREEN = "DASHBOARD_STRUCTURES_TOWER_FULLSCREEN",
    DASHBOARD_FINISHING_TOWER_FULLSCREEN = "DASHBOARD_FINISHING_TOWER_FULLSCREEN",
    DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM = "DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM",
    DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART = "DASHBOARD_FINISHING_FULLSCREEN_TOWER_BARCHART",
    DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM = "DASHBOARD_FINISHING_FULLSCREEN_TOWER_BARCHART_SM",
    DASHBOARD_FINISHING_TOWER_FULLSCREEN_ALL = "DASHBOARD_FINISHING_TOWER_FULLSCREEN_ALL",
    DASHBOARD_FINISHING_TOWER_LANDING = "DASHBOARD_FINISHING_TOWER_LANDING",
    DASHBOARD_FINISHING_TCA_LANDING = "DASHBOARD_FINISHING_TCA_LANDING",
    DASHBOARD_FINISHING_TCA_LANDING_BARATHEON = "DASHBOARD_FINISHING_TCA_LANDING_BARATHEON",
    DASHBOARD_FINISHING_TCA_FULLSCREEN = "DASHBOARD_FINISHING_TCA_FULLSCREEN",
    DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON = "DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON",
    SNAGS_TABLE = "SNAGS_TABLE",
    SNAGS_TABLE_FULLSCREEN = "SNAGS_TABLE_FULLSCREEN",
    UNIT_ACT_INFO_TABLE = "UNIT_ACT_INFO_TABLE",
    LEGACY_TABLE = "LEGACY_TABLE",
    LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE = "LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE",
    CHECKLIST_REPORTS_TABLE = "CHECKLIST_REPORTS_TABLE",
    FORM_DATA_TABLE = "FORM_DATA_TABLE",
    DEFAULT = "DEFAULT"
}

/* Differentiation for tabs, and different summaries */
export enum DashboardTypes {
    STRUCTURES_TOWER_TYPE = "STRUCTURES_TOWER_TYPE",
    FINISHING_TOWER_TYPE = "FINISHING_TOWER_TYPE",
    TABS = "TABS",
};

export enum Dashboards {
    STRUCTURES_TOWER = "DASHBOARD_STRUCTURES_TOWER",
    FINISHING_TOWER = "DASHBOARD_FINISHING_TOWER",
    FINISHING_TCA_LANDING = "DASHBOARD_FINISHING_TCA_LANDING",
    HANDOVER_TCA_LANDING = "DASHBOARD_HANDOVER_TCA_LANDING",
    STRUCTURES_TCA_LANDING = "DASHBOARD_STRUCTURES_TCA_LANDING",
    FINISHING_ECA_LANDING = "DASHBOARD_FINISHING_ECA_LANDING",
    HANDOVER_ECA_LANDING = "DASHBOARD_HANDOVER_ECA_LANDING",
    STRUCTURES_ECA_LANDING = "DASHBOARD_STRUCTURES_ECA_LANDING",
    FINISHING_BASEMENT_LANDING = "DASHBOARD_FINISHING_BASEMENT_LANDING",
    HANDOVER_BASEMENT_LANDING = "DASHBOARD_HANDOVER_BASEMENT_LANDING",
    STRUCTURES_BASEMENT_LANDING = "DASHBOARD_STRUCTURES_BASEMENT_LANDING"
};

export enum TabTypes {
    LANDING = "LANDING",
    FULLSCREEN = "FULLSCREEN"
};

export enum GOT {
    STARK = "STARK",
    BARATHEON = "BARATHEON",
};

export enum DownloadStatuses {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    DONE = "DONE"
};

export enum TicketStatusCategories {
    OPEN = "open",
    WIP = "wip",
    CLOSED = "close",
    ESCALATED = "escalated",
    INVALID = "invalid",
    REJECTED = "rejected",
    READY = "ready",
};

export enum RequestPriorities {
    SUPER_CRITICAL = "Super Critical",
    CRITICAL = "Critical",
    URGENT = "Urgent",
    HIGH = "High",
    MEDIUM = "Medium",
    NORMAL = "Normal",
    LOW = "Low",
    NO_PRIORITY = "No Priority",
    L1 = "L1",
    L2 = "L2",
    L3 = "L3",
    L4 = "L4",
    L5 = "L5",
    L6 = "L6",
};

export enum HistoryEvents {
    CREATED = "created",
    ASSIGNED = "assigned",
    CHANGED = "changed",
    UPDATED = "updated",
    SET = "set",
    STATUS = "changed",
    COMPLETED = "completed",
    ADDED = "uploaded",
    DELETED = "deleted",
    CLAIMED = "claimed",
    UNCLAIMED = "unclaimed",
};

export enum HistoryEventCategories {
    TICKET = "ticket",
    USER_TASK = "User task",
    STATUS = "Status",
    PRIORITY = "priority",
    DUE_DATE = "Due date",
    FILE = "file",
    ASSIGNEE = "assignee",
    TITLE = "title of the ticket",
    DESCRIPTION = "description of the ticket"
};

export enum NotificationEventTypes {
    TASK_ASSIGNED = "task_assigned",
    CORE_FIELD_CHANGED = "core_field_changed",
    COMMENT_ADDED = "comment_added",
    TASK_COMPLETED = "task_completed",
    DUE_DATE_CROSSED = "due_date_crossed",
    TASK_PENDING = "task_pending",
    MESSAGE_NOTIFICATION = "message_notification",
    TICKET_DELETED = "ticket_deleted",
    TICKET_CREATED = "ticket_created",
};

export enum FeatureGranularity {
    CUSTOMER = "CUSTOMER",
    PROJECT = "PROJECT",
    MODULE = "MODULE",
    TICKET_TYPE = "TICKET_TYPE",
};

export enum PermissionResources {
    TICKET = "ticket",
    TICKET_TYPE = "ticket_type",
    FILTERED_VIEW = "filtered_view",
};


export enum CommonApiEntities {
    BLOCKS = "blocks",
    UNIT_TYPES = "unitTypes",
    ACTIVITY_TYPES = "activityTypes",
    UNITS = "units",
    UNIT_ACTIVITIES = "unitActivities",
    FLOORS = "floors",
};

export enum ActivityStatuses {
    CONFIGURED = "CONFIGURED",
    PLANNED = "PLANNED",
    HOLD = "HOLD",
    ACTIVE = "ACTIVE",
    DONE = "DONE",
    ARCHIVED = "ARCHIVED",
};

export enum ProgressReportFrequencies {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
};

export enum ClaimActions {
    CLAIM = "CLAIM",
    UNCLAIM = "UNCLAIM",
    TRANSFER = "TRANSFER",
};

// deprecated. TODO: Get rid of
export function getView(phase: SnapshotOut<typeof ePhase>, screen?: SnapshotOut<typeof eScreen>): BricksView {
    switch (phase) {
        case 'finishing': return screen === 'landing' ? BricksView.FINLP : BricksView.CONVENTIONALF;
        case 'structures':
        default: return BricksView.CONVENTIONALS;
    }
}

// deprecated. TODO: Get rid of
export function getColl(param: IParams): BricksColl {
    const { phase, screen, graphType } = param;
    switch (phase) {
        case 'finishing': return screen === 'landing' || graphType === "barChart" ? BricksColl.FINLP : BricksColl.FINFS;
        case 'structures': return BricksColl.POUR;
        default: throw new Error("oopsiee! not implemented");
    }
}

// TODO Get concrete list of new phase-spacetype combos to support.

export function getIdFromParam(param: IParams, isLg?: boolean): Pages {
    // Currently all tca dashboard pages are the same as finishing. Implement
    // here for any specific phase-spaceType combo filters.
    const { screen, phase, graphType, section, spaceType, tower } = param;
    switch (section) {
        case 'dashboard':
            if (spaceType === "tower") {
                if (phase === "finishing") {
                    if (screen === "fullscreen") {
                        if (graphType === "barChart")
                            return isLg ? Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART : Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM;
                        return isLg ? Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN : Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM;
                    }
                    else if (screen === "landing") { return Pages.DASHBOARD_FINISHING_TOWER_LANDING; }
                }
                else if (phase === "structures") {
                    if (screen === "landing") { return Pages.DASHBOARD_STRUCTURES_TOWER_LANDING; }
                    else if (screen === "fullscreen") { return Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN; }
                }
            }
            else if (spaceType === "tca" || spaceType === "eca" || spaceType === "basement") {
                if (screen === "fullscreen") {
                    if (getThrones(graphType) === GOT.BARATHEON) {
                        return Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON
                    }
                    else return Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN
                }
                else if (screen === "landing") {
                    if (getThrones(graphType) === GOT.BARATHEON) {
                        return Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON
                    }
                    else { return Pages.DASHBOARD_FINISHING_TCA_LANDING }
                }
            }
        case 'tasks':
        case 'snags': if (graphType === 'table') {
            if (tower) { return Pages.SNAGS_TABLE_FULLSCREEN; }
            return Pages.SNAGS_TABLE;
        }
        case 'unit-act-info': if (graphType === 'table') { return Pages.UNIT_ACT_INFO_TABLE; }
        case 'legacy': if (graphType === 'table') {
            if (phase === 'finishing' && screen === 'fullscreen') { return Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE; }
            return Pages.LEGACY_TABLE;
        }
        case 'checklist-reports': if (graphType === 'table') { return Pages.CHECKLIST_REPORTS_TABLE; }
        case 'form-data': return Pages.FORM_DATA_TABLE;
        default: return Pages.DEFAULT;
    }
}

// TODO Get concrete list of new phase-spacetype combos to support.
export function getDashboard(param: IParams): Dashboards | string | null {
    const { phase, spaceType, screen, tab } = param;
    switch (phase) {
        case 'structures': if (spaceType === 'tower') {
            return Dashboards.STRUCTURES_TOWER;
        }
            if (spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") {
                if (screen === 'fullscreen') { return `${tab}_${phase}_${spaceType}`; }
                if (spaceType === 'tca') { return Dashboards.STRUCTURES_TCA_LANDING; }
                if (spaceType === 'eca') { return Dashboards.STRUCTURES_ECA_LANDING; }
                if (spaceType === 'basement') { return Dashboards.STRUCTURES_BASEMENT_LANDING; }
            }
        case 'finishing': if (spaceType === 'tower') {
            return Dashboards.FINISHING_TOWER;
        }
            // limit which space types generate TCA dashboards for now.
            if (spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") {
                if (screen === 'fullscreen') { return `${tab}_${phase}_${spaceType}`; }
                if (spaceType === 'tca') { return Dashboards.FINISHING_TCA_LANDING; }
                if (spaceType === 'eca') { return Dashboards.FINISHING_ECA_LANDING; }
                if (spaceType === 'basement') { return Dashboards.FINISHING_BASEMENT_LANDING; }
                return Dashboards.FINISHING_TCA_LANDING;
            }
        case 'handover': if (spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") {
            if (screen === 'fullscreen') { return `${tab}_${phase}_${spaceType}`; }
            if (spaceType === 'tca') { return Dashboards.HANDOVER_TCA_LANDING; }
            if (spaceType === 'eca') { return Dashboards.HANDOVER_ECA_LANDING; }
            if (spaceType === 'basement') { return Dashboards.HANDOVER_BASEMENT_LANDING; }
            return Dashboards.HANDOVER_TCA_LANDING;
        }
        default: return null;
    }
}

export function getThrones(val: string): GOT {
    const house = val?.toUpperCase();
    switch (house) {
        case GOT.STARK:
        case 'STARK': return GOT.STARK;
        case GOT.BARATHEON:
        case 'BARATHEON': return GOT.BARATHEON;
        default: return GOT.STARK;
    }
}

export function capitalizeNames(...args: string[]) {
    return args.map(a => `${a[0].toUpperCase()}${a.slice(1)}`).join('');
    // return `${a[0].toUpperCase()}${a.slice(1)}${b[0].toUpperCase()}${b.slice(1)}`;
}

export function getBrickParam(param: IParams): string {
    const { spaceType, screen, phase } = param;
    switch (spaceType) {
        case 'tower': return capitalizeNames(phase, screen);
        default: return capitalizeNames(phase, screen, spaceType);
    }
}

interface IPrefixFunctionsInvoke {
    customerId?: string;
    projectId?: string;
    moduleId?: string;
    ticketTypeId?: string;
};
const __prefixFunctionsForResourceType = {
    [PermissionResources.TICKET]: (_: IPrefixFunctionsInvoke) => "",
    [PermissionResources.TICKET_TYPE]: ({ customerId }: IPrefixFunctionsInvoke) => `${customerId}_`,
    [PermissionResources.FILTERED_VIEW]: ({ customerId, projectId, moduleId }: IPrefixFunctionsInvoke) => `${customerId}_${projectId}_${moduleId}_`,
};

export function getPrefixForResourceType(type: string, inp: IPrefixFunctionsInvoke) {
    const fn = __prefixFunctionsForResourceType[type];
    if (!fn) {
        return ""
    }
    return fn(inp)
}

export function getEntityDropdownType(val: string) {
    switch (val) {
        case CommonApiEntities.ACTIVITY_TYPES:
        case CommonApiEntities.UNITS:
        case CommonApiEntities.FLOORS: return val;
        default: return "general";
    }
}

export enum FBTCustomReports {
    TENDER_TRACKER = 'tenderTracker',
    DPR_CAPTURE = 'dprCapture',
}
